define('ember-custom-actions/actions/resource', ['exports', 'ember', 'ember-custom-actions/actions/action'], function (exports, _ember, _emberCustomActionsActionsAction) {
  'use strict';

  var assign = _ember['default'].assign;

  exports['default'] = function (path) {
    var options = arguments.length <= 1 || arguments[1] === undefined ? {} : arguments[1];

    return function () {
      var payload = arguments.length <= 0 || arguments[0] === undefined ? {} : arguments[0];
      var customOptions = arguments.length <= 1 || arguments[1] === undefined ? {} : arguments[1];

      return _emberCustomActionsActionsAction['default'].create({
        model: this,
        options: assign(options, customOptions),
        path: path,
        payload: payload
      }).callAction();
    };
  };
});