define("ember-custom-actions/utils/normalize-payload", ["exports", "ember"], function (exports, _ember) {
  "use strict";

  var assert = _ember["default"].assert;
  var String = _ember["default"].String;
  var isArray = _ember["default"].isArray;

  function transformObject(object, operation) {
    if (object instanceof Object && !isArray(object)) {
      var _ret = (function () {
        var data = {};

        Object.keys(object).forEach(function (key) {
          data[String[operation](key)] = transformObject(object[key], operation);
        });

        return {
          v: data
        };
      })();

      if (typeof _ret === "object") return _ret.v;
    } else {
      return object;
    }
  }

  exports["default"] = function (payload, operation) {
    if (operation) {
      assert("This normalize method of custom action's payload does not exist. Check Ember.String documentation!", !!String[operation]);
      return transformObject(payload, operation);
    } else {
      return payload;
    }
  };
});