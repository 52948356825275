define('ember-custom-actions/serializers/json-api', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  var JSONAPISerializer = _emberData['default'].JSONAPISerializer;

  exports['default'] = JSONAPISerializer.extend({
    pushPayload: function pushPayload(store, payload) {
      var normalizedPayload = this._normalizeDocumentHelper(payload);
      return store.push(normalizedPayload);
    }
  });
});