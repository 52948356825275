define('ember-confirmed/components/ember-remodal-redux', ['exports', 'confirmer', 'ember-confirmed/templates/components/ember-remodal-redux'], function (exports, _confirmer, _emberConfirmedTemplatesComponentsEmberRemodalRedux) {
  'use strict';

  exports['default'] = Ember.Component.extend({
    remodal: Ember.inject.service(),

    layout: _emberConfirmedTemplatesComponentsEmberRemodalRedux['default'],
    tagName: '',

    closeOnOutsideClick: true,
    closeOnEscape: true,
    closeOnCancel: true,
    closeOnConfirm: '',
    modifier: true,
    hashTracking: false,

    showModal: Ember.computed.bool('modalResolver'),

    modalName: Ember.computed(function () {
      return Ember.guidFor(this) + '-modal';
    }),

    modal: Ember.computed('modalName', function () {
      return this.get('remodal.' + this.get('modalName') + '.modal');
    }).volatile(),

    openModal: function openModal() {
      var _this = this;

      var remodal = this.get('remodal');
      var modalName = this.get('modalName');
      return new _confirmer['default'](function (resolver) {
        _this.set('modalResolver', resolver);
        var promise = remodal.open(modalName);
        resolver.dispose(function () {
          // https://github.com/sethbrasile/ember-remodal/issues/3
          return promise.then(function () {
            var modalState = Ember.tryInvoke(_this.get('modal'), 'getState');
            // https://github.com/vodkabears/Remodal/issues/291
            if (modalState !== 'opened') {
              return;
            }
            return remodal.close(modalName);
          }).then(function () {
            return _this.set('modalResolver', null);
          });
        });
      });
    },
    didInsertElement: function didInsertElement() {
      var _this2 = this;

      this._super.apply(this, arguments);
      var registerModalController = this.get('registerModalController');
      var registerModalManager = this.get('registerModalManager') || registerModalController;
      false && !registerModalManager && Ember.assert('Missing required action \'registerModalManager\'', registerModalManager);

      Ember.deprecate('use registerModalManager instead of registerModalController', !registerModalController, { id: 'ember-redux-remodal.registerModalController', until: '3.0' });
      registerModalManager({
        getModal: function getModal() {
          return _this2.get('modal');
        },
        open: function open() {
          return _this2.openModal();
        },
        confirm: function confirm(v) {
          return _this2.send('resolve', 'confirm', v);
        },
        cancel: function cancel(v) {
          return _this2.send('resolve', 'cancel', v);
        },
        reject: function reject(v) {
          return _this2.send('resolve', 'reject', v);
        },
        error: function error(v) {
          return _this2.send('resolve', 'error', v);
        }
      });
    },

    actions: {
      opened: function opened() {
        Ember.tryInvoke(this, 'onOpen');
      },
      resolve: function resolve(method, value) {
        Ember.tryInvoke(this.get('modalResolver'), method, [value]);
      }
    }
  });
});