define("ember-data-change-tracker/transforms/json", ["exports", "ember-data/transform"], function (exports, _emberDataTransform) {
  "use strict";

  /**
   * This transform does not serializes to string,
   * with JSON.stringify, but leaves the object as is.
   *
   * The data often does not need to be stringified
   * so it's a valid case
   */
  exports["default"] = _emberDataTransform["default"].extend({
    serialize: function serialize(value) {
      return value;
    },

    deserialize: function deserialize(json) {
      if (typeof json === "string") {
        json = JSON.parse(json);
      }
      return json;
    }
  });
});