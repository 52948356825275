define('ember-href-to/helpers/href-to', ['exports', 'ember'], function (exports, _ember) {
  'use strict';

  function _toConsumableArray(arr) {
    if (Array.isArray(arr)) {
      for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) arr2[i] = arr[i];return arr2;
    } else {
      return Array.from(arr);
    }
  }

  function _toArray(arr) {
    return Array.isArray(arr) ? arr : Array.from(arr);
  }

  var getOwner = _ember['default'].getOwner;

  function hrefTo(context, targetRouteName) {
    var router = getOwner(context).lookup('router:main');
    if (router === undefined || router.router === undefined) {
      return;
    }

    for (var _len = arguments.length, rest = Array(_len > 2 ? _len - 2 : 0), _key = 2; _key < _len; _key++) {
      rest[_key - 2] = arguments[_key];
    }

    var lastParam = rest[rest.length - 1];

    var queryParams = {};
    if (lastParam && lastParam.isQueryParams) {
      queryParams = rest.pop();
    }

    var args = [targetRouteName];
    args.push.apply(args, rest);
    args.push({ queryParams: queryParams.values });

    return router.generate.apply(router, args);
  }

  exports.hrefTo = hrefTo;
  exports['default'] = _ember['default'].Helper.extend({
    compute: function compute(_ref, namedArgs) {
      var _ref2 = _toArray(_ref);

      var targetRouteName = _ref2[0];

      var rest = _ref2.slice(1);

      if (namedArgs.params) {
        return hrefTo.apply(undefined, [this].concat(_toConsumableArray(namedArgs.params)));
      } else {
        return hrefTo.apply(undefined, [this, targetRouteName].concat(_toConsumableArray(rest)));
      }
    }
  });
});