define('ember-data-has-many-through/macros/concat', ['exports'], function (exports) {

  /**
    @method hasManyThrough
    @param hasMany child
    @param hasMany childOfChild
  */

  'use strict';

  exports['default'] = function () {
    var childKey = arguments.length <= 0 ? undefined : arguments[0],
        childOfChildKey = arguments.length <= 1 ? undefined : arguments[1];

    return Ember.computed(childKey + '.@each.' + childOfChildKey, function () {
      var children = this.get(childKey) || [];
      false && !Ember.isArray(children) && Ember.assert('your child is not an array', Ember.isArray(children));

      var mappedChildren = children.map(function (child) {
        var childOfChild = child.get(childOfChildKey) || [];
        false && !Ember.isArray(childOfChild) && Ember.assert('your childOfChild is not an array', Ember.isArray(childOfChild));

        return childOfChild.toArray();
      });
      return [].concat.apply([], mappedChildren);
    });
  };
});