define('ember-custom-actions/utils/url-builder', ['exports', 'ember'], function (exports, _ember) {
  'use strict';

  var assert = _ember['default'].assert;
  var computed = _ember['default'].computed;
  var Object = _ember['default'].Object;

  exports['default'] = Object.extend({
    modelName: computed('model', function () {
      var _get = this.get('model');

      var constructor = _get.constructor;

      return constructor.modelName || constructor.typeKey;
    }),

    snapshot: computed('model', function () {
      return this.get('model')._createSnapshot();
    }),

    build: function build() {
      assert('You must provide a path for model action!', this.get('path'));
      assert('Model has to be persisted!', !(this.get('instance') && !this.get('model.id')));

      var id = this.get('instance') ? this.get('model.id') : null;
      return this._makeUrl(this._buildUrl(id));
    },

    _buildUrl: function _buildUrl(id) {
      return this.get('adapter').buildURL(this.get('modelName'), id, this.get('snapshot'), this.get('urlType'));
    },

    _makeUrl: function _makeUrl(url) {
      if (url.charAt(url.length - 1) === '/') {
        return '' + url + this.get('path');
      } else {
        return url + '/' + this.get('path');
      }
    }
  });
});