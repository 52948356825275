define('ember-href-to/href-to', ['exports', 'ember'], function (exports, _ember) {
  'use strict';

  var _createClass = (function () {
    function defineProperties(target, props) {
      for (var i = 0; i < props.length; i++) {
        var descriptor = props[i];descriptor.enumerable = descriptor.enumerable || false;descriptor.configurable = true;if ('value' in descriptor) descriptor.writable = true;Object.defineProperty(target, descriptor.key, descriptor);
      }
    }return function (Constructor, protoProps, staticProps) {
      if (protoProps) defineProperties(Constructor.prototype, protoProps);if (staticProps) defineProperties(Constructor, staticProps);return Constructor;
    };
  })();

  function _classCallCheck(instance, Constructor) {
    if (!(instance instanceof Constructor)) {
      throw new TypeError('Cannot call a class as a function');
    }
  }

  var _default = (function () {
    function _default(applicationInstance, event) {
      var target = arguments.length <= 2 || arguments[2] === undefined ? event.target : arguments[2];
      return (function () {
        _classCallCheck(this, _default);

        this.applicationInstance = applicationInstance;
        this.event = event;
        this.target = target;
        var hrefAttr = this.target.attributes.href;
        this.url = hrefAttr && hrefAttr.value;
      }).apply(this, arguments);
    }

    _createClass(_default, [{
      key: 'maybeHandle',
      value: function maybeHandle() {
        if (this.shouldHandle()) {
          this.handle();
        }
      }
    }, {
      key: 'shouldHandle',
      value: function shouldHandle() {
        return this.isUnmodifiedLeftClick() && this.isNotIgnored() && this.hasNoTargetBlank() && this.hasNoActionHelper() && this.hasNoDownload() && this.isNotLinkComponent() && this.recognizeUrl();
      }
    }, {
      key: 'handle',
      value: function handle() {
        var router = this._getRouter();
        var urlWithoutRoot = this.getUrlWithoutRoot();

        router.handleURL(urlWithoutRoot);
        router.router.updateURL(urlWithoutRoot);
        this.event.preventDefault();
      }
    }, {
      key: 'isUnmodifiedLeftClick',
      value: function isUnmodifiedLeftClick() {
        var e = this.event;

        return (e.which === undefined || e.which === 1) && !e.ctrlKey && !e.metaKey;
      }
    }, {
      key: 'hasNoTargetBlank',
      value: function hasNoTargetBlank() {
        var attr = this.target.attributes.target;
        return !attr || attr.value !== '_blank';
      }
    }, {
      key: 'isNotIgnored',
      value: function isNotIgnored() {
        return !this.target.attributes['data-href-to-ignore'];
      }
    }, {
      key: 'hasNoActionHelper',
      value: function hasNoActionHelper() {
        return !this.target.attributes['data-ember-action'];
      }
    }, {
      key: 'hasNoDownload',
      value: function hasNoDownload() {
        return !this.target.attributes.download;
      }
    }, {
      key: 'isNotLinkComponent',
      value: function isNotLinkComponent() {
        var isLinkComponent = false;
        var id = this.target.id;
        if (id) {
          var componentInstance = this._getContainer(this.applicationInstance).lookup('-view-registry:main')[id];
          isLinkComponent = componentInstance && componentInstance instanceof _ember['default'].LinkComponent;
        }

        return !isLinkComponent;
      }
    }, {
      key: 'recognizeUrl',
      value: function recognizeUrl() {
        var url = this.url;
        var didRecognize = false;

        if (url) {
          var router = this._getContainer().lookup('router:main');
          var rootUrl = this._getRootUrl();
          var isInternal = url.indexOf(rootUrl) === 0;
          var urlWithoutRoot = this.getUrlWithoutRoot();

          didRecognize = isInternal && router.router.recognizer.recognize(urlWithoutRoot);
        }

        return didRecognize;
      }
    }, {
      key: 'getUrlWithoutRoot',
      value: function getUrlWithoutRoot() {
        var url = this.url;
        var rootUrl = this._getRootUrl();
        return url.substr(rootUrl.length - 1);
      }
    }, {
      key: '_getRouter',
      value: function _getRouter() {
        return this._getContainer().lookup('router:main');
      }
    }, {
      key: '_getContainer',
      value: function _getContainer() {
        return 'lookup' in this.applicationInstance ? this.applicationInstance : this.applicationInstance.container;
      }
    }, {
      key: '_getRootUrl',
      value: function _getRootUrl() {
        var router = this._getRouter();
        var rootURL = router.rootURL;

        if (rootURL.charAt(rootURL.length - 1) !== '/') {
          rootURL = rootURL + '/';
        }

        return rootURL;
      }
    }]);

    return _default;
  })();

  exports['default'] = _default;
});