define('ember-data-change-tracker/mixins/keep-only-changed', ['exports'], function (exports) {

  // EmberData does not serialize hasMany relationships by default
  'use strict';

  exports['default'] = Ember.Mixin.create({
    keepValue: function keepValue(record, key) {
      return record.get('isNew') || record.didChange(key);
    },

    serializeAttribute: function serializeAttribute(snapshot, json, key) {
      if (this.keepValue(snapshot.record, key)) {
        return this._super.apply(this, arguments);
      }
    },

    serializeBelongsTo: function serializeBelongsTo(snapshot, json, relationship) {
      if (this.keepValue(snapshot.record, relationship.key)) {
        return this._super.apply(this, arguments);
      }
    },

    serializeHasMany: function serializeHasMany(snapshot, json, relationship) {
      if (this.keepValue(snapshot.record, relationship.key)) {
        return this._super.apply(this, arguments);
      }
    }
  });
});