define('ember-custom-actions/actions/action', ['exports', 'ember', 'ember-custom-actions/utils/url-builder', 'ember-custom-actions/utils/normalize-payload', 'ember-custom-actions/config'], function (exports, _ember, _emberCustomActionsUtilsUrlBuilder, _emberCustomActionsUtilsNormalizePayload, _emberCustomActionsConfig) {
  /* eslint ember-suave/no-direct-property-access:1 */

  'use strict';

  var assign = _ember['default'].assign;
  var getOwner = _ember['default'].getOwner;
  var computed = _ember['default'].computed;
  var Object = _ember['default'].Object;
  var ObjectProxy = _ember['default'].ObjectProxy;
  var ArrayProxy = _ember['default'].ArrayProxy;
  var PromiseProxyMixin = _ember['default'].PromiseProxyMixin;

  var promiseTypes = {
    array: ArrayProxy.extend(PromiseProxyMixin),
    object: ObjectProxy.extend(PromiseProxyMixin)
  };

  exports['default'] = Object.extend({
    model: null,
    options: {},
    payload: {},
    instance: false,

    store: computed.reads('model.store'),

    modelName: computed('model', function () {
      var _get = this.get('model');

      var constructor = _get.constructor;

      return constructor.modelName || constructor.typeKey;
    }),

    adapter: computed('modelName', 'store', function () {
      return this.get('store').adapterFor(this.get('modelName'));
    }),

    serializer: computed('modelName', 'store', function () {
      return this.get('store').serializerFor(this.get('modelName'));
    }),

    appConfig: computed('model', function () {
      var config = getOwner(this.get('model')).resolveRegistration('config:environment').emberCustomActions || {};
      return Object.create(config);
    }),

    defaultConfig: computed(function () {
      return Object.create(_emberCustomActionsConfig['default']);
    }),

    config: computed('defaultConfig', 'options', 'appConfig', function () {
      return Object.create(assign(this.get('defaultConfig'), this.get('appConfig'), this.get('options')));
    }),

    requestType: computed('config.type', function () {
      return this.get('config.type').toUpperCase();
    }),

    urlType: computed.or('config.urlType', 'requestType'),

    url: computed('model', 'path', 'urlType', 'instance', 'adapter', function () {
      return _emberCustomActionsUtilsUrlBuilder['default'].create({
        path: this.get('path'),
        adapter: this.get('adapter'),
        urlType: this.get('urlType'),
        instance: this.get('instance'),
        model: this.get('model')
      }).build();
    }),

    data: computed('config.{normalizeOperation,ajaxOptions}', 'payload', function () {
      var payload = this.get('payload') instanceof window.Object ? this.get('payload') : {};
      var data = (0, _emberCustomActionsUtilsNormalizePayload['default'])(payload, this.get('config.normalizeOperation'));
      return assign(this.get('config.ajaxOptions'), { data: data });
    }),

    promiseType: computed('config.promiseType', function () {
      return promiseTypes[this.get('config.promiseType')];
    }),

    callAction: function callAction() {
      var promise = this._promise();
      return this.get('promiseType') ? this.get('promiseType').create({ promise: promise }) : promise;
    },

    _promise: function _promise() {
      var _this = this;

      return this.get('adapter').ajax(this.get('url'), this.get('requestType'), this.get('data')).then(function (response) {
        if (_this.get('config.pushToStore') && response.data) {
          return _this.get('serializer').pushPayload(_this.get('store'), response);
        } else {
          return response;
        }
      });
    }
  });
});