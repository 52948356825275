define('npm:abortcontroller-polyfill/dist/polyfill-patch-fetch', function(){ return { 'default': require('abortcontroller-polyfill/dist/polyfill-patch-fetch')};})
define('npm:baffle', function(){ return { 'default': require('baffle')};})
define('npm:buffer', function(){ return { 'default': require('buffer')};})
define('npm:copy-to-clipboard', function(){ return { 'default': require('copy-to-clipboard')};})
define('npm:detect-browser', function(){ return { 'default': require('detect-browser')};})
define('npm:download-in-browser', function(){ return { 'default': require('download-in-browser')};})
define('npm:gaussian', function(){ return { 'default': require('gaussian')};})
define('npm:humanize-string', function(){ return { 'default': require('humanize-string')};})
define('npm:query-string', function(){ return { 'default': require('query-string')};})
define('npm:random-number', function(){ return { 'default': require('random-number')};})
define('npm:raygun4js', function(){ return { 'default': require('raygun4js')};})
define('npm:relaxed-json', function(){ return { 'default': require('relaxed-json')};})
define('npm:slik', function(){ return { 'default': require('slik')};})
define('npm:sprintf-js', function(){ return { 'default': require('sprintf-js')};})
define('npm:uuid', function(){ return { 'default': require('uuid')};})
define('npm:whatwg-fetch', function(){ return { 'default': require('whatwg-fetch')};})
define('npm:zxcvbn', function(){ return { 'default': require('zxcvbn')};})