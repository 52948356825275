define('ember-custom-actions/config', ['exports'], function (exports) {
  'use strict';

  exports['default'] = {
    type: 'PUT',
    ajaxOptions: {},
    pushToStore: false,
    normalizeOperation: '',
    promiseType: null
  };
});