define('ember-data-has-many-through/macros/has-many-through', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  /**
    @method hasManyThrough
    @param hasMany child
    @param hasMany childOfChild
  */

  exports['default'] = function () {
    var childKey = arguments.length <= 0 ? undefined : arguments[0];
    var childOfChildKey = arguments.length <= 1 ? undefined : arguments[1];

    // dont key on `${childKey}.@each.${childOfChildKey}` or it will be run several times
    // BUT implemented that way it wouldn't update when childOfChildKey records are
    // deleted without the observer `notify${childKey.classify()}OnDelete`
    return Ember.computed(childKey + '.@each', function (key) {
      childOfChildKey = childOfChildKey || key;
      var self = this;
      var observerForChildOfChild = function observerForChildOfChild() {
        if (!self.isDestroyed) {
          self.notifyPropertyChange(key);
        }
      };

      return _emberData['default'].PromiseArray.create({
        promise: this.get(childKey).then(function (children) {
          var all = [];
          var res = [];
          var isBelongsTo = void 0;
          //children could be undefined for an API error, for example
          children = children || [];
          children.forEach(function (child) {
            // takes into account the case where the hasMany on the child
            // is not a promise (MF.Array for example)
            // or undefined (just a property returning null)
            var childOfChildIsPromise = child.get(childOfChildKey) && child.get(childOfChildKey).then;
            var prom = childOfChildIsPromise ? child.get(childOfChildKey) : Ember.RSVP.resolve(child.get(childOfChildKey));

            all.pushObject(prom.then(function (childrenOfChild) {
              if (childrenOfChild) {
                isBelongsTo = !childrenOfChild.toArray;
                res.pushObjects(isBelongsTo ? [childrenOfChild] : childrenOfChild.toArray());
              } else {
                isBelongsTo = true;
              }
            }));
          });
          return Ember.RSVP.all(all).then(function () {
            children.forEach(function (child) {
              // add observer for when a childOfChild is added / destroyed
              if (isBelongsTo) {
                child.removeObserver(childOfChildKey + '.isDeleted', self, observerForChildOfChild);
                //child.removeObserver(`${childOfChildKey}.isRejected`, self, observerForRejected);
                //child.addObserver(`${childOfChildKey}.isRejected`, self, observerForRejected);
                child.addObserver(childOfChildKey + '.isDeleted', self, observerForChildOfChild);
              } else if (child.get(childOfChildKey + '.firstObject') instanceof Ember.Object) {
                child.removeObserver(childOfChildKey + '.@each.isDeleted', self, observerForChildOfChild);
                //child.removeObserver(`${childOfChildKey}.@each.isRejected`, self, observerForRejected);
                //child.addObserver(`${childOfChildKey}.@each.isRejected`, self, observerForRejected);
                child.addObserver(childOfChildKey + '.@each.isDeleted', self, observerForChildOfChild);
              } else {
                child.removeObserver(childOfChildKey + '.[]', self, observerForChildOfChild);
                //child.removeObserver(`${childOfChildKey}.@each.isRejected`, self, observerForRejected);
                //child.addObserver(`${childOfChildKey}.@each.isRejected`, self, observerForRejected);
                child.addObserver(childOfChildKey + '.[]', self, observerForChildOfChild);
              }
            });
            // remove duplicates
            return res.filter(function (item, pos) {
              return item && res.indexOf(item) === pos && (!item.isDeleted || !item.get('isDeleted')) && !item.isDestroyed; //ED 2.14.10
            });
          }, function (res) {
            children.forEach(function (child) {
              if (isBelongsTo) {
                child.removeObserver(childOfChildKey + '.isRejected', self, observerForChildOfChild);
                child.addObserver(childOfChildKey + '.isRejected', self, observerForChildOfChild);
              } else {
                child.removeObserver(childOfChildKey + '.@each.isRejected', self, observerForChildOfChild);
                child.addObserver(childOfChildKey + '.@each.isRejected', self, observerForChildOfChild);
              }
            });
            return Ember.RSVP.reject(res);
          });
        })
      });
    });
  };
});